import React from "react"
import Layout from "../components/layout/layout"
import SEOComponent from "../components/seo"
import Partnership from "../components/partnership/Partnership";


const Customers = () => (
  <Layout>
    <SEOComponent title="Become a Partner" />
    <Partnership/>
  </Layout>
)

export default Customers;