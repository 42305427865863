import React, { Component } from 'react';
import PartnershipForm from './PartnershipForm';
import partnership_1 from '../../../static/images/partnership_1.png';
import partnership_2 from '../../../static/images/partnership_2.png';
import partnership_3 from '../../../static/images/partnership_3.png';

export default class Partnership extends Component {

    state = {
        partner_types:[
            {
                img: partnership_1,
                title: 'Referral Partners',
                text: 'Introduce Amber and leave the rest to us!',
                width: '80px'
            },
            {
                img: partnership_2,
                title: 'Product Integration Partner',
                text: 'Become a technology partner by integrating Amber with your platform',
                width: '55px'
            },
            {
                img: partnership_3,
                title: 'Strategic Partner',
                text: 'Get involved from lead to closure and become a strategic partner to inFeedo',
                width: '80px'
            },
        ]
    }

    render() {
        const { partner_types } = this.state;
        return (
            <div className="has-text-centered bg-path-3">
                <h1 className="is-size-3-tablet is-size-4-mobile has-text-weight-bold pd-top-75">
                    <span>Grow with Amber</span>
                </h1>
                <p className="columns column is-7 mar-auto mg-top-10">Join us as we support leaders across the world with the solution that maximises employee experience and propels us towards the Future of Work. </p>
                    <PartnershipForm/>
                    <div className="pd-bottom-100 pd-top-10">
                        <p className="has-text-weight-bold mg-bottom-20">Partner with Amber as: </p>
                            <div className="columns column is-10 flex-wrap mar-auto">
                            {
                                partner_types.map((partner, index) => (
                                    <div key={index} className="column is-4 pd-60">
                                        <img src={partner.img} width={partner.width} alt={partner.title}/>
                                        <p className="has-text-weight-bold mg-10">{partner.title}</p>
                                        <p>{partner.text}</p>
                                    </div>
                                ))
                            }
                            </div>
                    </div>
            </div>
        )
    }
}
